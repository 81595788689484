import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/en/Layout'

const NotFoundPage = () => {
  return (
    <Layout>
      <div className="not-found-page contact">
        <h1>404 :(</h1>
        <h2>Oops! Page not found!</h2>
        <p>It seems like we couldn't find the page you were looking for.</p>
        <p style={{ marginTop: 0 }}>Maybe searching could help.</p>
        <Link to="/en">Go back home</Link>
      </div>
    </Layout>
  )
}

export default NotFoundPage
